import React, {useContext} from 'react';
import {Redirect} from "react-router-dom";

import {MainContext} from "../contexts/MainContext";

export default function UnAuthorized(props)
{
    const {main} = useContext(MainContext);

    return (
    <>
        {
            (function ()
            {
                if (main.validated)
                {
                    if (main.expired || main.token === null)
                        return props.children;

                    if (main.activated)
                        return <Redirect to={'/activate'}/>

                    return <Redirect to={'/'}/>
                }
            })()
        }
    </>
    );
}