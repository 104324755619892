import React, {createContext, useState, useEffect, useContext} from 'react';
import {useLocation, useParams} from "react-router-dom"

import {ComponentContext} from "./ComponentContext";
import {MainContext} from "./MainContext";

export const AnalyticContext = createContext();

export default function AnalyticContextProvider(props)
{
	const {alert, loading, setLoading, setAlert, resetComponentContext} = useContext(ComponentContext);
	const {main, resetToken, setMainContext} = useContext(MainContext);

	const location = useLocation();
	const {id} = useParams();

	const [analytic, setAnalyticContext] = useState({
		valid: false,
		validated: false,
		pageName: false,
	});

	useEffect(() =>
	{
		console.log("=========== INITIAL MERCHANT PAGE LOAD ==========");

		setAnalyticContext({
			...analytic,
			valid: false,
			validated: false,
		})

		setLoading({
			...loading,
			show: true,
		});

		fetch(`${main.apiUrl}/analytic/validate`, {
			method: 'POST', // or 'PUT'
			headers: {
				'Content-Type': 'application/json',
				"Authorization": `Basic ${main.token}`
			},
			body: JSON.stringify({
				pageID: id
			})
		})
		.then(response => response.json())
		.then(data =>
		{
			setLoading({
				...loading,
				show: false,
			});

			console.log(data);

			if (!data.invalidToken)
			{
				let analyticContext = {
					validated: true,
					valid: true,
				};

				if (!data.success)
				{
					setAlert({
						...alert,
						show: true,
						type: data.type,
						message: data.message
					})

					analyticContext.valid = false;
				}
				else
					analyticContext.pageName = data.data.name;

				setAnalyticContext({
					...analytic,
					...analyticContext
				})
			}
			else
				resetToken(data.type, data.message);

		}).catch((err) =>
		{
			console.log('錯誤:', err);
		});

	}, [location]);


	return (
	<AnalyticContext.Provider value={{analytic, setAnalyticContext}}>
		{props.children}
	</AnalyticContext.Provider>
	);
}
