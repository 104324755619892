import React, {useContext, useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {DateRangePicker} from 'rsuite';
import moment from 'moment-timezone';
import {ResponsivePie} from '@nivo/pie';

import {MainContext} from "../../../contexts/MainContext";
import {AnalyticContext} from "../../../contexts/AnalyticContext";

import Loading from "../../../components/Loading";
import Demographic from "../../../components/Demographic";
import DemographicRanking from "../../../components/DemographicRanking";
import AgeGroupDistribution from "../../../components/AgeGroupDistribution";


export default function ReachDemographicContainer(props)
{
    const {main} = useContext(MainContext);

    const {id} = useParams();

    const [demographics, setDemographics] = useState({
        ageGenderBar: false,
        ageGenderPie: false,
        locale: false,
        city: false,
        reach: 0,
        engagedUsers: 0,
        engagementRate: 0,
        availableDates: [],
        country: false,
        loading: false,
    })

    useEffect(() =>
    {
        setDemographics({
            ...demographics,
            loading: true,
        })

        console.log("Using Effect!!!!!!!!!");

        fetch(`${main.apiUrl}/analytic/demographic/impressions`, {
            method: 'POST',
            headers: {"Authorization": `Basic ${main.token}`},
            body: JSON.stringify({
                pageID: id,
            }),
        }).then(response => response.json())    // one extra step
        .then(data =>
        {
            console.log(data);
            if (!data.hasOwnProperty('invalidToken'))
            {
                setDemographics({
                    ...demographics,
                    ageGenderBar: data.data.ageGenderBar,
                    ageGenderPie: data.data.ageGenderPie,
                    locale: data.data.locale,
                    city: data.data.city,
                    country: data.data.country,
                    reach: data.data.reach,
                    engagedUsers: data.data.engagedUsers,
                    engagementRate: data.data.engagementRate,
                    availableDates: data.data.dates,
                    loading: false,
                })
            }
            else
                main.resetToken(data.type, data.message);

        }).catch((err) =>
        {
            console.log('錯誤:', err);
        })
    }, [])

    const getDemographicReach = (since = null, until = null) =>
    {
        setDemographics({
            ...demographics,
            loading: true,
        })

        fetch(`${main.apiUrl}/analytic/demographic/impressions`, {
            method: 'POST',
            headers: {"Authorization": `Basic ${main.token}`},
            body: JSON.stringify({
                pageID: id,
                since: since,
                until: until,
            }),
        }).then(response => response.json())    // one extra step
        .then(data =>
        {
            if (!data.hasOwnProperty('invalidToken'))
            {
                console.log(data);

                setDemographics({
                    ...demographics,
                    ageGenderBar: data.data.ageGenderBar,
                    ageGenderPie: data.data.ageGenderPie,
                    locale: data.data.locale,
                    city: data.data.city,
                    country: data.data.country,
                    reach: data.data.reach,
                    engagedUsers: data.data.engagedUsers,
                    engagementRate: data.data.engagementRate,
                    availableDates: data.data.dates,
                    loading: false,
                })
            }
            else
                main.resetToken(data.type, data.message);

        }).catch((err) =>
        {
            console.log('錯誤:', err);
        })
    }

    return (
    <section className={`content analytic ${props.sidebar}`}>
        <header className="content__title">
            <h1
            className="mr-auto">Reach Demographic<small>Page Reach By Gender, Age and Regions</small>
            </h1>
            <button id="exportData" className="btn btn-theme">Export
            </button>
        </header>

        {
            (demographics.availableDates.length) && <div className="dateRangePickerWrapper">

                <Loading componentLoader={true} loading={demographics.loading}/>

                <DateRangePicker cleanable={false}
                                 ranges={[]}
                                 defaultValue={[moment(demographics.availableDates[0]).toDate(),
                                     moment(demographics.availableDates[0]).subtract(7, 'day').toDate()
                                 ]}
                                 disabledDate={date => (demographics.availableDates.indexOf(moment(date).format("YYYY-MM-DD")) < 0)}
                                 onChange={(dates) =>
                                 {
                                     getDemographicReach(dates[0], dates[1])
                                 }}
                />
            </div>
        }

        {
            (true) &&
            <div className="row">

                <div className="col-lg-4 col-md-6 col-12 mb-5">
                    <div className="card metric stats h-100">
                        <div className="card-body">
                            <h4 className="card-title">Total Reach</h4>
                            <div className={`stats__info no-chart text-primary`}>{demographics.reach}</div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12 mb-5">
                    <div className="card metric stats h-100">
                        <div className="card-body">
                            <h4 className="card-title">Engaged Users</h4>
                            <div className={`stats__info no-chart`}
                                 style={{color: '#f93964'}}>{demographics.engagedUsers}</div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12 mb-5">
                    <div className="card metric stats h-100">
                        <div className="card-body">
                            <h4 className="card-title">Engagement Rate</h4>
                            <div className={`stats__info no-chart text-success`}>{demographics.engagementRate}%</div>
                        </div>
                    </div>
                </div>

            </div>
        }

        {
            (demographics.ageGenderPie) && <>
                <div className="row mx-0">
                    <h4 className="mr-auto my-4">Age Group Distribution</h4>
                </div>

                <div className="row">
                    <AgeGroupDistribution data={demographics.ageGenderPie}/>
                </div>
            </>
        }
        {
            (demographics.ageGenderBar) && <>
                <div className="row mx-0">
                    <h4 className="mr-auto my-4">Gender Distribution</h4>
                </div>

                <div className="row" style={{height: 500}}>
                    <Demographic data={demographics.ageGenderBar}/>
                </div>
            </>
        }

        {
            (demographics.country || demographics.city || demographics.locale) &&
            <div className="row mx-0">
                <h4 className="mr-auto my-4">Regional Distribution</h4>
            </div>
        }

        <div className="row my-4">

            {
                (demographics.country) &&
                <DemographicRanking
                title={`By Country`}
                type={`country`}
                data={demographics.country}
                />
            }

            {
                (demographics.city) &&
                <DemographicRanking
                title={`By City`}
                type={`city`}
                data={demographics.city}
                />
            }

            {
                (demographics.locale) &&
                <DemographicRanking
                title={`By Language`}
                type={`locale`}
                data={demographics.locale}
                />
            }
        </div>
    </section>
    )
    ;
}