import React from 'react';

export default function Notification(props)
{
    return (
    <div className="scrollbar">
        <div className="listview listview--hover listview--truncate">
            <a href="" className="listview__item">
                <img className="avatar-img" src="demo/img/profile-pics/1.jpg" alt=""/>

                <div className="listview__content">
                    <h4>David Villa Jacobs</h4>
                    <p>Sorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mattis lobortis
                        sapien
                        non posuere</p>
                </div>
            </a>

            <a href="" className="listview__item">
                <img className="avatar-img" src="demo/img/profile-pics/5.jpg" alt=""/>
                <div className="listview__content">
                    <h4>Candice Barnes</h4>
                    <p>Quisque non tortor ultricies, posuere elit id, lacinia purus curabitur.</p>
                </div>
            </a>

            <a href="" className="listview__item">
                <img className="avatar-img" src="demo/img/profile-pics/3.jpg" alt=""/>
                <div className="listview__content">
                    <h4>Jeannette Lawson</h4>
                    <p>Donec congue tempus ligula, varius hendrerit mi hendrerit sit amet. Duis ac quam
                        sit
                        amet leo feugiat iaculis</p>
                </div>
            </a>

            <a href="" className="listview__item">
                <img className="avatar-img" src="demo/img/profile-pics/4.jpg" alt=""/>
                <div className="listview__content">
                    <h4>Darla Mckinney</h4>
                    <p>Duis tincidunt augue nec sem dignissim scelerisque. Vestibulum rhoncus sapien sed
                        nulla aliquam lacinia</p>
                </div>
            </a>

            <a href="" className="listview__item">
                <img className="avatar-img" src="demo/img/profile-pics/2.jpg" alt=""/>
                <div className="listview__content">
                    <h4>Rudolph Perez</h4>
                    <p>Phasellus a ullamcorper lectus, sit amet viverra quam. In luctus tortor vel nulla
                        pharetra bibendum</p>
                </div>
            </a>
        </div>
    </div>
    );
};