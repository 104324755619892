import React, {useContext, useState} from 'react';
import {CSSTransition} from 'react-transition-group';

import {ComponentContext} from "../contexts/ComponentContext";

export default function Notifications(props)
{
	const {notification, setNotification} = useContext(ComponentContext);

	const closeNotification = () =>
	{
		setNotification({
			...notification,
			show: false,
			id: '',
		});
	}

	return (
	<>
		<CSSTransition classNames={{
			appear: 'active animated',
			appearActive: 'active animated fadeInLeft faster',
			appearDone: 'active animated fadeInLeft faster',
			enter: 'active animated fadeInLeft faster',
			enterActive: 'active animated fadeInLeft faster',
			enterDone: 'active animated fadeInLeft faster',
			exit: 'active animated fadeOutLeft faster',
			exitActive: 'active animated fadeOutLeft faster',
			exitDone: 'active animated fadeOutLeft faster',
		}} in={notification.show}>
			<aside className="sidebar notifications"
			       onAnimationEnd={(e) => (!notification.show) && e.target.classList.remove('active')}>
				<div className="notifications__panel d-block" id={notification.id}>
					<div className="sidebar__header">
						<i className="zwicon-arrow-left sidebar__close"
						   onClick={closeNotification}></i>
						<h2>Messages <small>23 Unread messages</small></h2>

						<div className="actions">
							<a href="" className="actions__item"><i className="zwicon-plus"></i></a>
						</div>
					</div>
					<div className="notifications__body">
						{props.children}
					</div>
				</div>
			</aside>
		</CSSTransition>
		{(notification.show) && <div className="sidebar-backdrop" onClick={closeNotification}/>}
	</>
	);
};