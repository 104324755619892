import React, {useContext, useEffect, useState} from 'react';

import {MainContext} from "../contexts/MainContext";

export default function LoginContainer()
{
    const {main, setMainContext} = useContext(MainContext);

    const [login, setLogin] = useState({
        disabled: false,
        popup: false,
        subscribed: false
    });

    const checkPopup = () =>
    {
        const check = setInterval(() =>
        {
            console.log()
            if (!login.popup || login.popup.closed || login.popup.closed === undefined)
            {
                clearInterval(check);
                console.log("Popup Closed");
                setLogin({
                    ...login,
                    disabled: false,
                    popup: null
                })
            }
        }, 1000)
    };

    // Launches the popup by making a request to the server and then
    // passes along the socket id so it can be used to send back user
    // data to the appropriate socket on the connected client.
    const openPopup = () =>
    {
        const screenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const screenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const screenWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        const screenHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

        const width = 800;
        const height = 800;

        const systemZoom = screenWidth / window.screen.availWidth;
        const left = (screenWidth - width) / 2 / systemZoom + screenLeft;
        const top = (screenHeight - height) / 2 / systemZoom + screenTop;
        const url = `${main.apiUrl}/editor/login?socketId=${main.socket.id}`;

        return window.open(url, '_blank',
        `toolbar=no, location=no, directories=no, status=no, menubar=no, 
      scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
      height=${height}, top=${top}, left=${left}`);
    };

    // Kicks off the processes of opening the popup on the server and listening
    // to the popup. It also disables the login button so the user can not
    // attempt to login to the provider twice.
    const startAuth = (e) =>
    {
        if (!login.disabled)
        {
            e.preventDefault();
            login.popup = openPopup();
            checkPopup();

            setLogin({
                ...login,
                disabled: true,
            })
        }
    };

    useEffect(() =>
    {
        if (login.popup && !login.subscribed)
        {
            if (main.socket)
            {
                console.log("Not Subscribed");

                main.socket.on(main.socket.id, data =>
                {
                    if (login.popup === null)
                        console.log("Container Is NULL!!!");

                    setTimeout(function ()
                    {
                        login.popup.focus();
                        login.popup.close();
                    }, 500)

                    console.log(data);

                    main.socket.close();

                    setMainContext({
                        ...main,
                        token: data.token,
                        expired: false,
                        activated: data.activated,
                        socket:null
                    });

                    window.localStorage.setItem('token', data.token);
                });

                setLogin({
                    ...login,
                    subscribed: true,
                });
            }
        }
    }, [login.popup]);

    return <div className="login">
        <div className="login__block active" id="login-main">
            <div className="login__header">
                <img className="mb-2" src={process.env.PUBLIC_URL + "/images/logo.png"}/>
                Facebook Page Auto Reply
            </div>
            <div className="login__body">
                <button className="login__btn mt-1 border-0 outline" onClick={startAuth}>Sign In
                    With
                    Facebook
                </button>
            </div>
        </div>
    </div>
}