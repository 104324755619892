import React from 'react';
import {NavLink} from "react-router-dom";

export default function MenuItem(props)
{
    return (
    <NavLink exact={true} to={props.location} activeClassName={`text-active-${props.activeColor} active`}>
        <i className={`zwicon-${props.icon} text-${props.activeColor}`}/> {props.label}
    </NavLink>
    );
};






