import React, {useContext, useEffect, useState} from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

export default function ActivationContainer()
{
    const {loading,setLoading} = useContext(ComponentContext);
    const {main, setMainContext, resetToken} = useContext(MainContext);

    const [container, setContainer] = useState({
        init: false,
    });

    const formik = useFormik({
        initialValues: {
            activationCode: "",
        },
        validationSchema: Yup.object({
            activationCode: Yup.string().required('Please Enter Activation Code.').test('verify', 'Invalid Activation Code.', function (value)
            {
                setMainContext({
                    ...main,
                    loading: true,
                });

                return new Promise((resolve, reject) =>
                {
                    fetch(main.apiUrl + "/editor/activate", {
                        method: 'POST', // or 'PUT'
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Basic ${main.token}`,
                        },
                        body: JSON.stringify({
                            code: value,
                        })
                    })
                    .then(response => response.json())
                    .then(data =>
                    {
                        if (!data.hasOwnProperty('invalidToken'))
                        {
                            setMainContext({
                                ...main,
                                loading: false,
                            });

                            if (data.success)
                            {
                                setMainContext({
                                    ...main,
                                    activated: true,
                                    loading: false,
                                });

                                return resolve(true);
                            }

                            return resolve(false);

                        }
                        else
                        {
                            console.log("INVALID TOKEN !!!");
                            resetToken(data.type, data.message);
                        }

                        return resolve(false);
                    }).catch((err) =>
                    {
                        console.log('錯誤:', err);
                    });

                })
            }),
        }),

        validateOnChange: false,

        onSubmit: (values) =>
        {

        }
    })

    useEffect(() =>
    {
        if (!container.init)
        {
            setContainer({
                ...container,
                init: true,
            });
        }
    }, [container.init]);

    return (
    <div className="login">
        <div className={"login__block active widePanel"}>
            <div
            className={"login__header h5 font-weight-light"}>
                Account Activation Required
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="login__body">
                    <div className="login__inputs">
                        <div className="form-group">
                            <input type="text" name="activationCode"
                                   className="form-control text-center"
                                   placeholder="Please Enter Activation Code Provided"
                                   onChange={formik.handleChange}
                                   value={formik.values.activationCode}/>
                        </div>

                        {((formik.touched.activationCode && formik.errors.activationCode)) &&
                        <div className="invalid-feedback"
                             style={{display: 'block'}}>{formik.errors.activationCode}</div>}
                    </div>
                    <button className="login__btn border-0 outline">Submit</button>
                </div>
            </form>
        </div>
    </div>
    );
}