import React, {useContext, useState, useEffect} from 'react';
import {CSSTransition} from 'react-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar'

import {ComponentContext} from "../contexts/ComponentContext";
import Loading from "./Loading";

export default function Modal(props)
{
    const {modalComponents, setModalComponents} = useContext(ComponentContext);

    const [modal, setModal] = useState({
        show: false,
    });

    const dismiss = () =>
    {
        if (props.id !== false)
        {
            modalComponents[props.id].show = false;
            modalComponents[props.id].loading = false;

            setModalComponents({
                ...modalComponents
            });
        }
    }

    useEffect(() =>
    {
        if (!modalComponents.hasOwnProperty(props.id) && props.id !== false)
        {
            modalComponents[props.id] = {
                show: false,
                loading: false,
            }

            setModalComponents({
                ...modalComponents
            });
        }

    }, [props.id]);

    return (
        <>
            <CSSTransition classNames={{
                appear: 'active animated',
                appearActive: 'active animated fadeIn faster',
                appearDone: 'active animated fadeIn faster',
                enter: 'active animated fadeIn faster',
                enterActive: 'active animated fadeIn faster',
                enterDone: 'active animated fadeIn faster',
                exit: 'active animated fadeOut faster',
                exitActive: 'active animated fadeOut faster',
                exitDone: 'active animated fadeOut faster',
            }} in={modalComponents.hasOwnProperty(props.id) && modalComponents[props.id].show}
                           onEntered={(e) =>
                           {
                               setModal({...modal, show: true});
                               console.log("Entering Modal")
                           }}>
                <div className="modal-backdrop"
                     onAnimationEnd={(e) =>
                     {
                         if (modalComponents.hasOwnProperty(props.id) && !modalComponents[props.id].show)
                         {
                             e.target.classList.remove('active');

                             console.log("Animation Ended!!!");

                             delete modalComponents[props.id];

                             setModalComponents({
                                 ...modalComponents
                             });
                         }
                     }}/>
            </CSSTransition>

            <CSSTransition classNames={{
                appear: 'active animated',
                appearActive: 'active animated fadeInDown faster',
                appearDone: 'active animated fadeInDown faster',
                enter: 'active animated fadeInDown faster',
                enterActive: 'active animated fadeInDown faster',
                enterDone: 'active animated fadeInDown faster',
                exit: 'active animated fadeOutUp faster',
                exitActive: 'active animated fadeOutUp faster',
                exitDone: 'active animated fadeOutUp faster',
            }} in={modal.show && (modalComponents.hasOwnProperty(props.id)) && modalComponents[props.id].show}
                           onExited={dismiss}>
                <div id="modal" className="modal" tabIndex="-1" role="dialog" aria-labelledby="Add Page"
                     aria-hidden="true"
                     onClick={(e) => setModal({...modal, show: false})}
                     onAnimationEnd={(e) => (!modal.show) && e.target.classList.remove('active')}
                >
                    <div className={`modal-dialog modal-${props.size} ${props.centered && 'modal-dialog-centered'}`}
                         onClick={(e) => e.stopPropagation()}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-highlight weight-900">
                                    {props.title}
                                </h5>
                            </div>

                            {
                                (props.scrollable) ? <PerfectScrollbar>
                                    <div className="modal-body">
                                        {props.children}
                                    </div>
                                </PerfectScrollbar> : <div className="modal-body">
                                    {props.children}
                                </div>
                            }

                            {(modalComponents.hasOwnProperty(props.id)) && (modalComponents[props.id].loading) &&
                            <Loading componentLoader={true} loading={modalComponents[props.id].loading}/>
                            }
                        </div>
                    </div>
                </div>

            </CSSTransition>
        </>
    );
};