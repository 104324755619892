import React, {useContext} from 'react';
import {Redirect} from "react-router-dom";

import {MainContext} from "../contexts/MainContext";

export default function Editor(props)
{
    const {main} = useContext(MainContext);

    return (
    <>
        {
            (function ()
            {
                if (main.validated)
                {
                    console.log("VALIDATED!!!!");

                    if (main.expired || main.token === null)
                        return <Redirect to={'/login'}/>

                    if(!main.activated)
                        return <Redirect to={'/activate'}/>

                    return props.children;
                }
            })()
        }
    </>
    );
}