import React, {useContext} from 'react';

import {ComponentContext} from "../contexts/ComponentContext";

export default function Loading(props)
{
	const {loading} = useContext(ComponentContext);

	return (
	<>
		{
			(function ()
			{
				if (props.componentLoader)
				{
					return (props.loading) &&
					<div className={`${props.class} page-loader component-loader`}>
						<div className="loader">
							<i></i>
							<i></i>
							<i></i>
							<i></i>
							<i></i>
							<i></i>
						</div>
					</div>
				}

				return (loading.show) && <div className={`${props.class} page-loader`}>
					<div className="loader">
						<i></i>
						<i></i>
						<i></i>
						<i></i>
						<i></i>
						<i></i>
					</div>
				</div>

			})()
		}
	</>
	);
}