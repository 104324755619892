import React, {useContext} from 'react';
import {CSSTransition} from 'react-transition-group'

import {ComponentContext} from "../contexts/ComponentContext";

export default function Alert(props)
{
    const {alert, setAlert} = useContext(ComponentContext);

    const closeAlert = () =>
    {
        setAlert({
            ...alert,
            show: false,
        });
    }

    return (
    <CSSTransition classNames={{
        appear: 'active animated',
        appearActive: 'active animated fadeInRight faster',
        appearDone: 'active animated fadeInRight faster',
        enter: 'active animated fadeInRight faster',
        enterActive: 'active animated fadeInRight faster',
        enterDone: 'active animated fadeInRight faster',
        exit: 'active animated fadeOutRight faster',
        exitActive: 'active animated fadeOutRight faster',
        exitDone: 'active animated fadeOutRight faster',
    }} onEntered={() => setTimeout(closeAlert, 3000)} in={alert.show}>
        <div className={`alerts alert alert-${alert.type}`} role="alert" onAnimationEnd={(e) =>
        {
            if (!alert.show)
                e.target.classList.remove('active');
        }}>
            {alert.message}
        </div>
    </CSSTransition>
    );
};