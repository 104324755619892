import React, {useContext, useState, useEffect} from 'react';
import Tabulator from 'tabulator-tables';
import {Progress} from 'rsuite';

import {ComponentContext} from "../contexts/ComponentContext";
import {MainContext} from "../contexts/MainContext";

export default function Table(props)
{
	const {main, resetToken} = useContext(MainContext);
	const {tableComponents, setTableComponents} = useContext(ComponentContext);

	const {Line} = Progress;

	const [table, setTable] = useState({
		el: React.createRef(),
		init: false,
		timer: null
	});

	const undoEdit = () =>
	{
		tableComponents[props.id].undo();
	};

	const undoAll = () =>
	{
		for (let i = 0; i < tableComponents[props.id].getHistoryUndoSize() + 2; i++)
		{
			if (tableComponents[props.id].getHistoryUndoSize() > 0)
				tableComponents[props.id].undo();
		}
	};

	const setPage = () =>
	{
		console.log("Setting Table Page");
		tableComponents[props.id].setPage(tableComponents[props.id].getPage());
	};

	const add = () =>
	{
		tableComponents[props.id].addRow(JSON.parse(JSON.stringify(props.initialValues)));
	}

	const stripHtml = (html) =>
	{
		// Create a new div element
		var temporalDivElement = document.createElement("div");
		// Set the HTML content with the providen
		temporalDivElement.innerHTML = html;
		// Retrieve the text property of the element (cross-browser support)
		return temporalDivElement.textContent || temporalDivElement.innerText || "";
	}

	const matchAny = (data, filterParams) =>
	{
		var checkVal = filterParams.value.toLowerCase();
		var tmpIsCols = Array.isArray(filterParams.columns);
		var tmpKeyList = data;

		if (tmpIsCols)
		{
			tmpKeyList = {};
			for (var iPos in filterParams.columns)
			{
				var tmpCol = filterParams.columns[iPos];
				var tmpKey = tmpCol.field;
				if (tmpKey)
				{
					tmpKeyList[tmpKey] = true;
				}
			}
		}

		var match = false;
		var key = '';

		if (filterParams.startsWith === true)
		{
			for (key in tmpKeyList)
			{
				console.log(stripHtml(data[key]));
				if (stripHtml(data[key]).toLowerCase().indexOf(checkVal) === 0)
				{
					match = true;
					break;
				}
			}
		}
		else
		{
			outer:
			for (key in tmpKeyList)
			{
				if (data[key] !== null)
				{
					if (typeof data[key] === 'object')
					{
						for (var item in data[key])
						{
							if (stripHtml(data[key][item]).toLowerCase().indexOf(checkVal) > -1)
							{
								match = true;
								break outer;
							}
						}
					}
					else
					{
						if (stripHtml(data[key]).toLowerCase().indexOf(checkVal) > -1)
						{
							match = true;
							break;
						}
					}
				}
			}
		}

		return match;
	}

	const search = (e) =>
	{
		clearTimeout(table.timer);

		const keyword = e.target.value;

		table.timer = setTimeout(function ()
		{
			if (props.options.ajaxFiltering !== false)
			{
				tableComponents[props.id].setFilter([
					{field: "any", type: "=", value: keyword},
				])
			}
			else
				tableComponents[props.id].setFilter(matchAny, {columns: props.options.columns, value: keyword, startsWith: false});

		}, 300)
	}

	const setData=(e)=>
	{
		console.log("SETTING DATA........");
		tableComponents[props.id].setData(props.options.data);
	}

	useEffect(() =>
	{
		if (props.id && props.options && !tableComponents.hasOwnProperty(props.id))
		{
			tableComponents[props.id] = new Tabulator(table.el, {
				layout: "fitDataStretch",
				responsiveLayout: "collapse",
				responsiveLayoutCollapseStartOpen:false,
				pagination: "remote",
				ajaxConfig:
				{
					method: "GET",
					headers:
					{
						"Authorization": `Basic ${main.token}`
					}
				},
				ajaxFiltering: true,
				ajaxSorting: true,
				paginationSize: 20,
				paginationButtonCount: 10,
				reactiveData: true,
				ajaxResponse: function (url, params, response)
				{
					if (response.hasOwnProperty('invalidToken'))
					{
						resetToken(response.type, response.message);
					}
					else
						return response;
				},
				...props.options
			});

			setTableComponents({
				...tableComponents
			})
		}

	}, [props.id, props.options]);

	useEffect(() =>
	{
		if ('actions' in props)
		{
			for (var i = 0; i < props.actions.length; i++)
			{
				switch (props.actions[i])
				{
					case 'add':
						add();
						break;
					case 'undoEdit':
						undoEdit();
						break;
					case 'undoAll':
						undoAll();
						break;
					case 'setPage':
						setPage();
						break;
					case 'setData':
						setData();
						break;
					default:
						break;
				}
			}
		}
	}, [props.actions]);

	return (<div className="tableContainer w-100">
		<div className={`tableHeader pt-2 px-2 ${props.style}`}>
			<div className="filter">
				<label>
					<input type="text" className="tableSearch"
					       placeholder="Search For Records..." onKeyUp={search}/>
				</label>
			</div>

			{
				(props.options.pagination !== false) && <div className="pageLength">
					<label>
						<select
						onChange={(e) => tableComponents[props.id].setPageSize(e.target.value)}>
							<option value="20">20 Rows</option>
							<option value="50">50 Rows</option>
							<option value="100">100 Rows</option>
							<option value="150">150 Rows</option>
							<option value="200">200 Rows</option>
						</select>
					</label>
				</div>
			}

		</div>
		<div className={props.style} ref={el => (table.el = el)}/>
	</div>);
}