import React from 'react';

export default function Sidebar(props)
{
    const closeSideBar = () =>
    {
        document.getElementById('mainMenu').classList.toggle("sidebar--active");
        document.getElementById('mainmenu-backdrop').classList.toggle("active");
    }

    return (
        <>
            <aside id="mainMenu" class="sidebar navigation">
                <div class="scrollbar">
                    <ul class="navigation__menu">
                        {props.children}
                        {/*<li>
                    <a class="text-active-blue active" href="index.html"><i class="zwicon-home text-blue"></i>Dashboard</a>
                </li>
                <li>
                    <a href="widgets.html" class="text-active-green"><i
                    class="zwicon-layout-4 text-green"></i>Widgets</a>
                </li>
                <li class="navigation__sub">
                    <a href="" data-mae-action="submenu-toggle" class="text-active-amber"><i
                    class="zwicon-layout-2 text-amber"></i>Tables</a>
                    <ul>
                        <li>
                            <a href="tables.html">Normal Tables</a>
                        </li>
                        <li>
                            <a href="data-tables.html">Data Tables</a>
                        </li>
                    </ul>
                </li>*/}
                    </ul>
                </div>
            </aside>
            <div id='mainmenu-backdrop' className="mainmenu-backdrop" onClick={closeSideBar}/>
        </>
    );
};