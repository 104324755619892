import React from 'react';

import {ResponsivePie} from '@nivo/pie';

export default function AgeGroupDistribution(props)
{
    const theme = {
        background: "transparent",
        fontSize: 20,
        tooltip: {
            container: {
                color: 'white',
                background: '#000000',
            },
        }
    };

    const colors = {
        '13-17': "#27a4fb",
        '18-24': "#2cc56f",
        '25-34': "#ffc021",
        '35-44': "#f93964",
        '45-54': "#3F51B5",
        '55-64': "#FF5722",
        '65+': "#607D8B"
    }

    const CenteredMetric = ({dataWithArc, centerX, centerY}) =>
    {
        let total = 0
        dataWithArc.forEach(datum =>
        {
            total += datum.value
        })

        return (
        <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
            fill: '#dcf3ff',
            fontSize: '3rem',
            fontWeight: '600',
        }}
        >
            {total}
        </text>
        )
    }

    return (
    <>
        <div className='col-12 col-lg-6 my-4'>
            <div className={`row`} style={{height: 400}}>
                <ResponsivePie
                data={props.data}
                margin={{top: 0, right: 0, bottom: 0, left: 0}}
                innerRadius={0.5}
                padAngle={0.4}
                cornerRadius={4}
                colors={d => colors[d.data.age]}
                borderWidth={0}
                /*borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}*/
                enableRadialLabels={false}
                enableSliceLabels={false}
                sliceLabelsSkipAngle={10}
                sliceLabelsTextColor={`#dcf3ff`}
                legends={false}
                layers={['slices', 'radialLabels', 'sliceLabels', 'legends', CenteredMetric]}
                tooltip={({datum}) =>
                {
                    return <div className="d-flex flex-row align-items-end justify-content-around"
                                style={{background: '#fff', padding: '1rem 1.2rem', borderRadius: '.5rem'}}>
                        <div className='align-self-center mr-2'
                             style={{
                                 width: '1rem',
                                 background: colors[datum.data.age],
                                 borderRadius: '.1rem',
                                 padding: '.5rem 0'
                             }}/>
                        <div className='align-self-center'
                             style={{color: '#22313a', fontWeight: '500'}}>
                            {datum.data.gender}: {datum.value}
                        </div>
                    </div>
                }
                }
                theme={theme}
                />
            </div>
        </div>

        <div className='col-12 col-lg-6 my-4'>

            <table className="table table-striped mb-0">
                <thead>
                <tr>
                    <th>Age Group</th>
                    <th>Male</th>
                    <th>Female</th>
                    <th>Total</th>
                </tr>
                </thead>
                <tbody>

                {props.data.map((item, index) => (index % 2 === 0) && <>
                    <tr>
                        <td className={`align-middle`}>
                            <div className='d-inline-block mr-3'
                                 style={{
                                     width: '1rem',
                                     background: colors[props.data[index].age],
                                     borderRadius: '.1rem',
                                     padding: '.5rem 0'
                                 }}/>
                            <div className='d-inline-block align-middle'>
                                {props.data[index].age}
                            </div>
                        </td>
                        <td>{props.data[index].value}</td>
                        <td>{props.data[index + 1].value}</td>
                        <td>{props.data[index + 1].total}</td>
                    </tr>
                </>
                )}

                </tbody>
            </table>
        </div>
    </>
    )
}