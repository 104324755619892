import React from 'react';

import {ResponsiveBar} from '@nivo/bar';

export default function Demographic(props)
{
	const theme = {
		background: "transparent",
		axis: {
			tickColor: "#9bbcd1",
			ticks: {
				line: {
					stroke: "#9bbcd1"
				},
				text: {
					fill: "#9bbcd1"
				}
			},
			legend: {
				text: {
					fill: "#9bbcd1"
				}
			}
		},
		grid: {
			line: {
				stroke: "#9bbcd1"
			}
		}
	};

	const DifferenceLabels = ({bars, yScale}) =>
	{
		// space between top of stacked bars and total label
		const labelMargin = 70;

		return bars.map(({data: {data, indexValue}, x, width}, i) =>
		{
			// sum of all the bar values in a stacked bar

			if (i % 2 === 0)
			{
				return (
				<g
				transform={`translate(${x}, ${yScale(parseFloat(data['Male']))})`}
				key={`${indexValue}-${i}`}
				>
					<text
					// add any class to the label here
					className="bar-total-label"
					x={width / 2}
					y={parseFloat(data['Male'])}
					textAnchor="middle"
					alignmentBaseline="central"
					fontSize={`18px`}
					fontWeight={`700`}
					style={{
						fill: '#ffffff'
					}}
					>
						{data['previousMale']}%
					</text>
				</g>
				);
			}

			return (
			<g
			transform={`translate(${x}, 200)`}
			key={`${indexValue}-${i}`}
			>
				<text
				// add any class to the label here
				className="bar-total-label"
				x={width / 2}
				y={0}
				y={Math.abs(data['Female'])}
				textAnchor="middle"
				alignmentBaseline="central"
				fontSize={`18px`}
				fontWeight={`700`}
				style={{
					fill: '#ffffff'
				}}
				>
					{data['previousFemale']}%
				</text>
			</g>
			);
		});
	};

	const LifetimeLabels = ({bars, yScale}) =>
	{
		// space between top of stacked bars and total label
		const labelMargin = 70;

		return bars.map(({data: {data, indexValue}, x, width}, i) =>
		{
			// sum of all the bar values in a stacked bar
			if (i % 2 === 0)
			{
				return (
				<g
				transform={`translate(${x}, ${yScale(parseFloat(data['Male']))})`}
				key={`${indexValue}-${i}`}
				>
					<text
					// add any class to the label here
					className="bar-total-label"
					x={width / 2}
					y={parseFloat(data['Male'])}
					textAnchor="middle"
					alignmentBaseline="central"
					fontSize={`18px`}
					fontWeight={`700`}
					style={{
						fill: '#ffffff'
					}}
					>
						{data['toTotalMale']}%
					</text>
				</g>
				);
			}

			return (
			<g
			transform={`translate(${x}, 200)`}
			key={`${indexValue}-${i}`}
			>
				<text
				// add any class to the label here
				className="bar-total-label"
				x={width / 2}
				y={0}
				y={Math.abs(data['Female'])}
				textAnchor="middle"
				alignmentBaseline="central"
				fontSize={`18px`}
				fontWeight={`700`}
				style={{
					fill: '#ffffff'
				}}
				>
					{-1*data['toTotalFemale']}%
				</text>
			</g>
			);
		});
	};

	const GenderLabels = ({bars, yScale}) =>
	{
		// space between top of stacked bars and total label

		let total = 0;
		let maleTotal = 0;
		let femaleTotal = 0;

		bars.map(({data: {data, indexValue}, x, width}, i) =>
		{
			total += parseFloat(data['Male']) + Math.abs(data['Female']);
			maleTotal += parseFloat(data['Male']);
			femaleTotal += Math.abs(data['Female']);
		});

		return <>
			<g transform={`translate(-80, 0)`} key={`test`}>
				<text
				// add any class to the label here
				className="bar-total-label"
				x={0}
				y={0}
				textAnchor="middle"
				alignmentBaseline="central"
				fontSize={`20px`}
				fontWeight={`400`}
				style={{
					fill: '#27a4fb'
				}}
				>
					Male
				</text>
				<text
				// add any class to the label here
				className="bar-total-label"
				x={0}
				y={25}
				textAnchor="middle"
				alignmentBaseline="central"
				fontSize={`18px`}
				fontWeight={`400`}
				style={{
					fill: '#27a4fb'
				}}
				>
					({(maleTotal * 100 / total).toFixed(2)}% of Your Fans)
				</text>
			</g>
			<g transform={`translate(-80 380)`} key={`test`}>
				<text
				// add any class to the label here
				className="bar-total-label"
				x={0}
				y={0}
				textAnchor="middle"
				alignmentBaseline="central"
				fontSize={`20px`}
				fontWeight={`400`}
				style={{
					fill: '#f93964'
				}}
				>
					Female
				</text>
				<text
				// add any class to the label here
				className="bar-total-label"
				x={0}
				y={25}
				textAnchor="middle"
				alignmentBaseline="central"
				fontSize={`18px`}
				fontWeight={`400`}
				style={{
					fill: '#f93964'
				}}
				>
					({(femaleTotal * 100 / total).toFixed(2)}% of Your Fans)
				</text>
			</g>
		</>
	};

	const AgeGroupLabels = ({bars, yScale}) =>
	{
		return <>
		<rect fill="#dcf3ff" stroke-width="2" width='100%' height="30"  y="185" x="0"
			      stroke-linecap="null" stroke-linejoin="null" stroke-dasharray="null"
			      stroke="#dcf3ff" style={{
			      	'width': 'calc(100% - 255px)'
			}}/>
			<text
			// add any class to the label here
			className="bar-total-label"
			x={-80}
			y={200}
			textAnchor="middle"
			alignmentBaseline="central"
			fontSize={`16px`}
			fontWeight={`400`}
			style={{
				fill: '#9bbcd1'
			}}
			>
				Age Group
			</text>
			{
				bars.map(({data: {data, indexValue}, x, width}, i) =>
				{
					// sum of all the bar values in a stacked bar
					return <g
					transform={`translate(${x}, 200)`}
					/*transform={`translate(${x},0)`}*/
					key={`${indexValue}-${i}`}
					>
						<text
						// add any class to the label here
						className="bar-total-label"
						x={width / 2}
						y={0}
						textAnchor="middle"
						alignmentBaseline="central"
						fontSize={`16px`}
						fontWeight={`400`}
						style={{
							fill: '#394e5a'
						}}
						>
							{data['age']}
						</text>
					</g>
				})
			}
			</>

	};

	const TotalLabels = ({ bars, yScale }) => {
		// space between top of stacked bars and total label
		const labelMargin = 20;

		return bars.map(({ data: { data, indexValue }, x, width }, i) => {

			if (i % 2 === 0)
			{
				return (
				<g
				transform={`translate(${x}, ${yScale(data['Male']) - labelMargin})`}
				key={`${indexValue}-${i}`}
				>
					<text
					// add any class to the label here
					className="bar-total-label"
					x={width / 2}
					y={labelMargin / 4}
					textAnchor="middle"
					alignmentBaseline="central"
					// add any style to the label here
					style={{
						fill: "rgb(255, 255, 255)"
					}}
					>
						{data['toTotalMale']}%
					</text>
				</g>
				);
			}

			return (
			<g
			transform={`translate(${x}, ${yScale(data['Female']) - labelMargin})`}
			key={`${indexValue}-${i}`}
			>
				<text
				// add any class to the label here
				className="bar-total-label"
				x={width / 2}
				y={labelMargin*2}
				textAnchor="middle"
				alignmentBaseline="central"
				// add any style to the label here
				style={{
					fill: "rgb(255, 255, 255)"
				}}
				>
					{-1*data['toTotalFemale']}%
				</text>
			</g>
			);
		});
	};

	return (
	<ResponsiveBar
	height={500}
	margin={{top: 50, right: 80, bottom: 50, left: 180}}
	data={props.data}
	indexBy={'age'}
	keys={['Male', 'Female']}
	minValue={-100}
	maxValue={100}
	enableGridX={false}
	enableGridY={false}
	padding={0.2}
	labelTextColor={'#9bbcd1'}
	enableLabel={false}
/*	labelFormat={(value, id, data) =>
	{
		console.log(data);
		if (value >= 0)
			return <tspan y={-20} fontSize={`14px`}>{data['toTotalMale']}%</tspan>;

		return <tspan y={Math.abs(value) * 1.7 + 40} fontSize={`14px`}>{data['toTotalFemale']}%</tspan>;
	}}*/
	/*axisTop={{
		legend: 'AGE GROUP',
		legendPosition: 'middle',
		legendOffset: -30,
		legendFontSize: '16px',
		tickSize: 0,
		tickPadding: -5,
	}}*/
	/*markers={[
		{
			axis: 'y',
			value: 0,
			lineStyle: {stroke: '#f93964', strokeWidth: 1},
		},
	]}*/
	tooltipFormat={value=>Math.abs(value)+'%'}
	axisBottom={null}
	axisLeft={null}
	animate={true}
	motionStiffness={90}
	motionDamping={15}
	colors={['#27a4fb', '#f93964']}
	theme={theme}
	layers={
		(props.lifetime)?["grid", "axes", "bars", LifetimeLabels, GenderLabels, AgeGroupLabels, "markers", "legends"]:
		["grid", "axes", "bars", TotalLabels,DifferenceLabels, GenderLabels, AgeGroupLabels, "markers", "legends"]
	}
	/>
	);
}