import React, {createContext, useState} from 'react';

export const ComponentContext=createContext();

export default function ComponentContextProvider(props)
{

    const initialAlertState={
        show: false,
        type: '',
        message: '',
    };

    const initialNotificationState={
        id: false,
        show: false,
        content: []
    };

    const initialLoadingState={
        show: false,
    };

    const initialTablesState={};
    const initialModalsState={};


    const [alert, setAlert]=useState(initialAlertState);
    const [notification, setNotification]=useState(initialNotificationState);
    const [tableComponents, setTableComponents]=useState(initialTablesState);
    const [modalComponents, setModalComponents]=useState(initialModalsState);
    const [loading,setLoading]=useState(initialLoadingState);

    const resetComponentContext=() =>
    {
        setNotification({
            ...initialNotificationState
        });

        setTableComponents({
            ...initialTablesState
        });

        setModalComponents({
            ...initialModalsState
        });
    }

    return (
    <ComponentContext.Provider value={{loading,alert,notification,tableComponents,modalComponents,setLoading,setAlert,setNotification,setTableComponents,setModalComponents, resetComponentContext}}>
        {props.children}
    </ComponentContext.Provider>
    );
}
