import React, {useContext, useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {DateRangePicker} from 'rsuite';
import moment from 'moment-timezone';
import {ResponsivePie} from '@nivo/pie';

import {MainContext} from "../../../contexts/MainContext";
import {AnalyticContext} from "../../../contexts/AnalyticContext";

import Loading from "../../../components/Loading";
import Demographic from "../../../components/Demographic";
import DemographicRanking from "../../../components/DemographicRanking";
import AgeGroupDistribution from "../../../components/AgeGroupDistribution";


export default function EngagementDemographicContainer(props)
{
	const {main} = useContext(MainContext);

	const {id} = useParams();

	const [demographics, setDemographics] = useState({
		ageGenderBar: false,
		ageGenderPie:false,
		locale: false,
		city: false,
		country: false,
		loading: false,
	})

	useEffect(() =>
	{

		setDemographics({
			...demographics,
			loading: true,
		})

		fetch(`${main.apiUrl}/analytic/demographic/engagements`, {
			method: 'POST',
			headers: {"Authorization": `Basic ${main.token}`},
			body: JSON.stringify({
				pageID: id,
				since: moment().subtract(8, 'days').format('YYYY-MM-DD'),
				until: moment().subtract(1, 'days').format('YYYY-MM-DD'),
			}),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			if (!data.hasOwnProperty('invalidToken'))
			{
				console.log(data);

				setDemographics({
					...demographics,
					ageGenderBar: data.data.ageGenderBar,
					ageGenderPie: data.data.ageGenderPie,
					locale: data.data.locale,
					city: data.data.city,
					country: data.data.country,
					loading: false,
				})
			}
			else
				main.resetToken(data.type, data.message);

		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	}, [])

	const getDemographicLike = (since = null, until = null) =>
	{
		setDemographics({
			...demographics,
			loading: true,
		})

		fetch(`${main.apiUrl}/analytic/demographic/engagements`, {
			method: 'POST',
			headers: {"Authorization": `Basic ${main.token}`},
			body: JSON.stringify({
				pageID: id,
				since: since,
				until: until,
			}),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			if (!data.hasOwnProperty('invalidToken'))
			{
				console.log(data);

				setDemographics({
					...demographics,
					ageGenderBar: data.data.ageGenderBar,
					ageGenderPie: data.data.ageGenderPie,
					locale: data.data.locale,
					city: data.data.city,
					country: data.data.country,
					loading: false,
				})
			}
			else
				main.resetToken(data.type, data.message);

		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	}

	return (
	<section className={`content analytic ${props.sidebar}`}>
		<header className="content__title">
			<h1
			className="mr-auto">Reach Demographic<small>Page Reach By Gender, Age and Regions</small>
			</h1>
			<button id="exportData" className="btn btn-theme">Export
			</button>
		</header>

		<div className="dateRangePickerWrapper">

			<Loading componentLoader={true} loading={demographics.loading}/>

			<DateRangePicker cleanable={false}
			                 ranges={[]}
			                 defaultValue={[moment().subtract(8, 'days').toDate(),
				                 moment().subtract(1, 'day').toDate()
			                 ]}
			                 disabledDate={date => moment(date).isAfter(moment())}
			                 onChange={(dates) =>
			                 {
				                 getDemographicLike(dates[0], dates[1])
			                 }}
			/>
		</div>

		<div className="row mx-0">
			<h4 className="mr-auto my-4">Age Group Distribution</h4>
		</div>

		<div className="row">
			{(demographics.ageGenderPie) && <AgeGroupDistribution data={demographics.ageGenderPie}/>}
		</div>

		<div className="row mx-0">
			<h4 className="mr-auto my-4">Gender Distribution</h4>
		</div>

		<div className="row" style={{height: 500}}>
			{(demographics.ageGenderBar) && <Demographic data={demographics.ageGenderBar}/>}
		</div>


		<div className="row mx-0">
			<h4 className="mr-auto my-4">Regional Distribution</h4>
		</div>

		<div className="row my-4">

			<DemographicRanking
			title={`By Country`}
			type={`country`}
			data={demographics.country}
			/>

			<DemographicRanking
			title={`By City`}
			type={`city`}
			data={demographics.city}
			/>

			<DemographicRanking
			title={`By Language`}
			type={`locale`}
			data={demographics.locale}
			/>
		</div>
	</section>
	)
	;
}