import React, {createContext, useState, useEffect, useContext} from 'react';
import {useLocation} from "react-router-dom";
import io from "socket.io-client";

import {ComponentContext} from "./ComponentContext";

export const MainContext = createContext();

export default function MainContextProvider(props)
{
    const location = useLocation();

    const {alert, setAlert, loading, setLoading, resetComponentContext} = useContext(ComponentContext);

    const [main, setMainContext] = useState({
        apiUrl: process.env.REACT_APP_API_URL,
        appUrl: process.env.REACT_APP_APP_URL,
        token: (window.localStorage.getItem('token')) ? window.localStorage.getItem('token') : null,
        expired: true,
        validated: false,
        activated: false,
        socket: null,
    });

    const resetToken = (type, message) =>
    {
        console.log("RESETTING TOKEN!!!");

        window.localStorage.removeItem('token');

        setAlert({
            ...alert,
            show: true,
            type: type,
            message: message
        });

        setMainContext({
            ...main,
            token: null,
            validated: true,
            expired: true,
            activated: false,
        })
    }

    useEffect(() =>
    {
        console.log("=========== INITIAL PAGE LOAD ==========");

        if (main.token)
        {
            console.log("===== HAVE TOKEN, READY TO VALIDATE TOKEN =====");

            setMainContext({
                ...main,
                validated: false,
                activated: false,
            })

            setLoading({
                ...loading,
                show: true,
            });

            fetch(`${main.apiUrl}/editor/validate`, {
                method: 'POST', // or 'PUT'
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Basic ${main.token}`
                },
            })
            .then(response => response.json())
            .then(data =>
            {
                setLoading({
                    ...loading,
                    show: false,
                })

                if (!data.invalidToken)
                {
                    console.log("NO INVALID TOKEN!!!");

                    if (!data.activated)
                    {
                        console.log("Not Activated");
                        console.log(data);

                        setAlert({
                            ...alert,
                            show: true,
                            type: data.type,
                            message: data.message
                        })
                    }

                    setMainContext({
                        ...main,
                        validated: true,
                        expired: false,
                        activated: data.activated
                    });
                }
                else
                    resetToken(data.type, data.message);

            }).catch((err) =>
            {
                console.log('錯誤:', err);
            });
        }
        else
        {
            console.log("===== THERE IS NO TOKEN =====");

            setMainContext({
                ...main,
                validated: true,
                socket: io(main.apiUrl)
            })
        }

    }, [location]);

    useEffect(() =>
    {
        resetComponentContext();
    }, [main.token]);


    return (
    <MainContext.Provider value={{main, setMainContext, resetToken}}>
        {props.children}
    </MainContext.Provider>
    );
}
