import React from 'react';

import {ResponsiveLine} from '@nivo/line';

export default function Metric(props)
{
	const theme = {
		background: "transparent",
		axis: {
			tickColor: "#9bbcd1",
			ticks: {
				line: {
					stroke: "#9bbcd1"
				},
				text: {
					fill: "#9bbcd1"
				}
			},
			legend: {
				text: {
					fill: "#9bbcd1"
				}
			}
		},
		grid: {
			line: {
				stroke: "#9bbcd1"
			}
		}
	};

	const gradProps = {
		gradientUnits: 'userSpaceOnUse',
		x1: '0',
		y1: '0',
		x2: '0',
		y2: 100
	};

	return (
	<div className="col-lg-4 col-md-6 col-12 mb-5">
		<div className="card metric stats mb-0 h-100">
			<div className="card-body">
				<h4 className="card-title">{props.title}</h4>
				{(props.data.percentage > 0) &&
				<h6 className='text-success card-subtitle'>
					{props.data.percentage}%<i className="zwicon-arrow-up"></i>
				</h6>}

				{(Math.ceil(Math.abs(props.data.percentage)) === 0) &&
				<h6 className='text-muted card-subtitle'>
					{props.data.percentage}%
				</h6>}

				{(props.data.percentage < 0) &&
				<h6 className='text-danger card-subtitle'>
					{props.data.percentage}%<i className="zwicon-arrow-down"></i>
				</h6>}

				<div className={`stats__info ${!props.chart && 'no-chart'}`}>{props.data.total}</div>

				{
					(props.chart) &&
					<div className="row" style={{height: 100}}>

						<ResponsiveLine
						data={props.data.graph}
						sliceTooltip={({slice}) =>
						{
							return <div className="sliceToolTips">
								<div>
									<table>
										<tbody>
										{slice.points.slice(0).reverse().map((point, index) =>
										{
											let html = '';

											if (index === 0)
											{
												return <>
													<tr>
														<td>
															{point.data.xFormatted}
														</td>
													</tr>
													<tr>
														<td>
															{(props.title === 'Total New Likes') ?
															<strong className={`newlike`}>{point.data.yFormatted}</strong>:
															<strong>{(point.data.yFormatted >= 0) ? point.data.yFormatted : -1 * point.data.yFormatted}</strong>
															}
															<span
															style={{'color': point.borderColor}}><strong>{point.serieId}</strong></span>
														</td>
													</tr>
												</>
											}

											return <tr>
												<td>
													<strong>
														{(point.data.yFormatted > 0) ? point.data.yFormatted : -1 * point.data.yFormatted}
													</strong><span
												style={{'color': point.borderColor}}><strong>{point.serieId}</strong></span>
												</td>
											</tr>

											/*return <tr>
												<td><span style={{'backgroundColor': point.borderColor}}></span>
												</td>
												<td>{point.serieId}</td>
												<td><strong>8</strong></td>
											</tr>*/

										})}
										</tbody>
									</table>
								</div>
							</div>
						}}
						curve={"cardinal"}
						margin={{top: 20, right: 0, bottom: 10, left: 0}}
						enableSlices={'x'}
						enableArea={true}
						areaOpacity={0.05}
						enablePoints={false}
						xScale={{type: 'point'}}
						yScale={{type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false}}
						axisTop={null}
						axisRight={null}
						axisBottom={null}
						axisLeft={null}
						enableGridX={false}
						enableGridY={false}
						colors={['#27a4fb', '#f93964', '#ffe21f','#2cc56f'/*, '#f93964'*//*,'#2cc56f'*//*,'#ffc021'*/]}
						pointSize={10}
						pointColor={{theme: 'background'}}
						pointBorderWidth={2}
						pointBorderColor={{from: 'serieColor'}}
						pointLabel="y"
						pointLabelYOffset={-12}
						useMesh={true}
						theme={theme}
						/>
					</div>
				}
			</div>

		</div>
	</div>
	);
}