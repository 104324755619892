import React, {useContext} from 'react';

import {ComponentContext} from "../contexts/ComponentContext";

import Sidebar from "./Sidebar";
import Notifications from "./Notifications";
import Notification from "./Notification";
import Header from "./Header";
import MenuItem from "./MenuItem";

export default function EditorPageLayout({component: Component, sidebar: sidebar})
{
    const {notification, setNotification} = useContext(ComponentContext);

    const closeNotification = () =>
    {
        setNotification({
            ...notification,
            show: false,
            id: ''
        })
    }

    return <>

        <Header sidebar={sidebar}/>

        <div className="main">
            {(sidebar) &&
            <>
                <Notifications>
                    <Notification/>
                </Notifications>

                <Sidebar>
                    <li>
                        <MenuItem activeColor={"blue"} icon={"home"} label={"Facebook Pages"}
                                  location={"/"}/>
                    </li>
                    {/*<li>
                        <MenuItem activeColor={"green"} icon={"layout-4"} label={"Facebook Pages"}
                                  location={"/home"}/>
                    </li>
                    <li className="navigation__sub">
                        <a href="" data-mae-action="submenu-toggle" className="text-active-amber"><i
                        className="zwicon-layout-2 text-amber"/>Tables</a>
                        <ul style={{listStyleType: 'none'}}>
                            <li>
                                <MenuItem activeColor={"blue"} icon={"home"} label={"Home"}
                                          location={"/home"}/>
                            </li>
                            <li>
                                <MenuItem activeColor={"blue"} icon={"home"} label={"Home"}
                                          location={"/home"}/>
                            </li>
                        </ul>

                    </li>*/}
                </Sidebar>
            </>
            }
            <Component sidebar={sidebar}/>
            {(notification.show) && <div className="sidebar-backdrop" onClick={closeNotification}/>}
        </div>
    </>

}