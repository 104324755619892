import React, {useEffect, useState, useContext} from 'react';
import {useParams, useLocation} from "react-router-dom";

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import Table from "../components/Table";
import Modal from "../components/Modal";

export default function FacebookInboxContainer(props)
{
	const {main, resetToken} = useContext(MainContext);
	const {modalComponents, tableComponents, alert, setModalComponents, setAlert} = useContext(ComponentContext);

	const {id} = useParams();

	const {location} = useLocation();

	const initialState = {
		id: false,
		data: false,
		paging: false,
	};



	const [posts, setPosts] = useState(initialState);

	const [table, setTable] = useState({
		id: 'PagePosts',
		options: {
			layout: "fitColumns",
			pagination:false,
			ajaxFiltering: false,
			ajaxSorting: false,
			placeholder: "No Contents Found.",
			initialSort: [
				{column: "id", dir: "desc"},
			],
			columns: [
				{
					formatter: "responsiveCollapse",
					width: 30,
					minWidth: 30,
					hozAlign: "center",
					resizable: false,
					headerSort: false
				},
				{
					title: "Conversation ID",
					field: "id",
					sorter: "string",
					minWidth: 200,
					editor: false,
					validator: 'required',
				},
				{
					title: "Inbox Link",
					field: "link",
					minWidth: 200,
					sorter: "string",
					editor: false,
					validator: 'required',
					formatter: (cell, formatterParams, onRendered) =>
					{
						const rowData = cell.getRow().getData();
						return `<a href="https://www.facebook.com${rowData.link}">
                                            <button type='button' class='btn btn-outline-primary'>https://www.facebook.com${rowData.link}</button>
                                        </a>`;
					},
				},
			],
		},
	});

	useEffect(() =>
	{
		fetchInbox();
	}, [location])

	const fetchInbox = (param = {}) =>
	{
		const body = {
			pageID: id
		}

		if (param.hasOwnProperty('prev'))
			body.prev = param.prev;

		if (param.hasOwnProperty('next'))
			body.next = param.next;

		console.log(body);

		fetch(`${main.apiUrl}/editor-page/inbox`, {
			method: 'POST',
			headers: {"Authorization": `Basic ${main.token}`},
			body: JSON.stringify(body),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			console.log(data);

			if (!data.hasOwnProperty('invalidToken'))
			{
				setTable({
					...table,
					options:{
						...table.options,
						data:data.data,
					},
					actions:['setData']
				})

				setPosts({
					...posts,
					...data,
				})
			}
			else
				resetToken(data.type, data.message);

		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	}


	return (
	<>
		<section className={`content ${props.sidebar}`}>
			<header className="content__title">
				<h1 className="mr-auto">Pages Posts<small>To View Page Posts and Comments</small>
				</h1>
			</header>

			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<Table {...table} style={`dark`}/>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				(posts.paging) && <div className="row justify-content-end">
					<div className="col text-right">
					{('before' in posts.paging.cursors) &&
					<div className="btn btn-theme px-4 mr-3" onClick={(e) =>
					{
						fetchInbox({prev: posts.paging.cursors.before})
					}}>PREV</div>}
					{('after' in posts.paging.cursors) &&
					<div className="btn btn-theme px-4" onClick={(e) =>
					{
						fetchInbox({next: posts.paging.cursors.after})
					}}>NEXT</div>}
					</div>
				</div>
			}
		</section>
	</>
	);
}