import React, {useContext} from 'react';
import {Redirect} from "react-router-dom";

import {AnalyticContext} from "../contexts/AnalyticContext";

export default function Analytic(props)
{
    const {analytic} = useContext(AnalyticContext);

    return (
    <>
        {
            (function ()
            {
                if (analytic.validated)
                {
                    console.log(analytic);

                    if (!analytic.valid)
                        return <Redirect to={'/'}/>

                    return props.children;
                }
            })()
        }
    </>
    );
}