import React, {useContext, useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {DatePicker} from 'rsuite';
import moment from 'moment-timezone';

import {MainContext} from "../../../contexts/MainContext";
import {AnalyticContext} from "../../../contexts/AnalyticContext";

import Loading from "../../../components/Loading";
import Demographic from "../../../components/Demographic";
import DemographicRanking from "../../../components/DemographicRanking";
import AgeGroupDistribution from "../../../components/AgeGroupDistribution";


export default function LikeDemographicContainer(props)
{
    const {main} = useContext(MainContext);

    const {id} = useParams();

    const [demographics, setDemographics] = useState({
        ageGenderBar: false,
        locale: false,
        city: false,
        country: false,
        loading: false,
        availableDates: [],
    })

    useEffect(() =>
    {

        setDemographics({
            ...demographics,
            loading: true,
        })

        fetch(`${main.apiUrl}/analytic/demographic/likes`, {
            method: 'POST',
            headers: {"Authorization": `Basic ${main.token}`},
            body: JSON.stringify({
                pageID: id,
                since: getWorkingDay(moment(), -3).format('YYYY-MM-DD'),
            }),
        }).then(response => response.json())    // one extra step
        .then(data =>
        {
            if (!data.hasOwnProperty('invalidToken'))
            {
                console.log(data);

                setDemographics({
                    ...demographics,
                    ageGenderBar: data.data.ageGenderBar,
                    ageGenderPie: data.data.ageGenderPie,
                    locale: data.data.locale,
                    city: data.data.city,
                    country: data.data.country,
                    availableDates: data.data.dates,
                    loading: false,
                })
            }
            else
                main.resetToken(data.type, data.message);

        }).catch((err) =>
        {
            console.log('錯誤:', err);
        })
    }, [])

    const getDemographicLike = (since = null) =>
    {
        setDemographics({
            ...demographics,
            loading: true,
        })

        fetch(`${main.apiUrl}/analytic/demographic/likes`, {
            method: 'POST',
            headers: {"Authorization": `Basic ${main.token}`},
            body: JSON.stringify({
                pageID: id,
                since: since
            }),
        }).then(response => response.json())    // one extra step
        .then(data =>
        {
            if (!data.hasOwnProperty('invalidToken'))
            {
                console.log(data);

                setDemographics({
                    ...demographics,
                    ageGenderBar: data.data.ageGenderBar,
                    ageGenderPie: data.data.ageGenderPie,
                    locale: data.data.locale,
                    city: data.data.city,
                    country: data.data.country,
                    availableDates: data.data.dates,
                    loading: false,
                })
            }
            else
                main.resetToken(data.type, data.message);

        }).catch((err) =>
        {
            console.log('錯誤:', err);
        })
    }

    const getWorkingDay = (date, difference) =>
    {
        if (difference >= 0)
        {
            while (difference > 0)
            {
                date = date.add(1, 'days');
                // decrease "days" only if it's a weekday.
                if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7)
                    difference--;
            }

            return date;
        }

        while (difference < 0)
        {
            date = date.subtract(1, 'days');
            // decrease "days" only if it's a weekday.
            if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7)
                difference++;
        }

        return date;
    }

    return (
    <section className={`content analytic ${props.sidebar}`}>
        <header className="content__title">
            <h1
            className="mr-auto">Fans Demographic (Lifetime)<small>Lifetime Likes By Gender, Age Group And
                Regions</small>
            </h1>
            <button id="exportData" className="btn btn-theme">Export
            </button>
        </header>

        {
            (demographics.availableDates.length) && <div className="dateRangePickerWrapper">

                <Loading componentLoader={true} loading={demographics.loading}/>

                <DatePicker cleanable={false}
                            ranges={[]}
                            defaultValue={moment(demographics.availableDates[0]).toDate()}
                            format={"DD MMM YYYY"}
                            oneTap={true}
                            disabledDate={date =>(demographics.availableDates.indexOf(moment(date).format("YYYY-MM-DD")) < 0)}
                            onChange={(date) =>
                            {
                                getDemographicLike(date)
                            }}
                />
            </div>
        }

        {
            (demographics.ageGenderPie) && <>
                <div className="row mx-0">
                    <h4 className="mr-auto my-4">Age Group Distribution</h4>
                </div>

                <div className="row align-items-center mb-5">
                    <AgeGroupDistribution data={demographics.ageGenderPie}/>
                </div>
            </>
        }

        {
            (demographics.ageGenderPie) && <>
                <div className="row mx-0">
                    <h4 className="mr-auto my-4">Gender Distribution</h4>
                </div>

                <div className="row" style={{height: 500}}>
                    {(demographics.ageGenderBar) && <Demographic lifetime={true} data={demographics.ageGenderBar}/>}
                </div>
            </>
        }
        {
            (demographics.country || demographics.city || demographics.locale) &&
            <div className="row mx-0">
                <h4 className="mr-auto my-4">Regional Distribution</h4>
            </div>
        }

        <div className="row my-4">
            {
                (demographics.country) &&
                <DemographicRanking
                title={`By Country`}
                type={`country`}
                data={demographics.country}
                />
            }

            {
                (demographics.city) &&
                <DemographicRanking
                title={`By City`}
                type={`city`}
                data={demographics.city}
                />
            }

            {
                (demographics.locale) &&
                <DemographicRanking
                title={`By Language`}
                type={`locale`}
                data={demographics.locale}
                />
            }
        </div>
    </section>
    );
}