import React, {useEffect, useState, useContext} from 'react';

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

import Table from "../components/Table";
import Modal from "../components/Modal";

export default function EditorPageContainer(props)
{
	const {main, resetToken} = useContext(MainContext);
	const {modalComponents, tableComponents, alert, setModalComponents, setAlert} = useContext(ComponentContext);

	const initialModalState = {
		id: false
	};

	const initialTableState = {
		id: 'EditorPage',
		initialValues:
		{
			pageID: null,
			name: null,
			status: true
		},
		options: false,
		style: 'dark',
		actions: [],
	};

	const initialFacebookPageTableState = {
		id: 'FacebookPages',
		initialValues:
		{
			pageID: null,
			name: null,
		},
		options: false,
		style: 'dark',
		actions: [],
		prev: false,
		next: false,
	};

	const [modal, setModal] = useState(initialModalState);
	const [table, setTable] = useState(initialTableState);
	const [facebookPageTable, setFacebookPageTable] = useState(initialFacebookPageTableState);

	const openFacebookPageList = (e) =>
	{
		if (!modalComponents.hasOwnProperty('FacebookPages'))
		{
			modalComponents["FacebookPages"] = {
				show: false,
				loading: false,
			}
		}

		modalComponents["FacebookPages"].show = true;

		setModalComponents({
			...modalComponents
		})
	};

	const fetchFacebookPage = (page) =>
	{
		let data = {
			prev: false,
			next: false,
		};

		data[page] = facebookPageTable[page];

		modalComponents["FacebookPages"].loading = true;

		setModalComponents({
			...modalComponents
		});

		fetch(`${main.apiUrl}/facebook/pages`, {
			method: 'POST',
			headers: {"Authorization": `Basic ${main.token}`},
			body: JSON.stringify(data),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			console.log(data);
			tableComponents[facebookPageTable.id].setData(data.data).then(() =>
			{
				facebookPageTable.prev = false;
				facebookPageTable.next = false;

				if (data.paging.hasOwnProperty('previous'))
				{
					console.log("HAVE PREV");
					facebookPageTable.prev = data.paging.cursors.before;
				}

				if (data.paging.hasOwnProperty('next'))
				{
					console.log("HAVE NEXT");
					facebookPageTable.next = data.paging.cursors.after;
				}

				setFacebookPageTable({
					...facebookPageTable
				});

				modalComponents["FacebookPages"].loading = false;

				setModalComponents({
					...modalComponents
				});

			});
		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	};

	const addEditorPage = (id) =>
	{
		modalComponents["FacebookPages"].loading = true;

		setModalComponents({
			...modalComponents
		});

		fetch(`${main.apiUrl}/editor/pages`, {
			method: 'POST',
			headers: {"Authorization": `Basic ${main.token}`},
			body: JSON.stringify({
				id: id
			}),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			if (!data.hasOwnProperty('invalidToken'))
			{
				let actions = ['undoAll']

				modalComponents["FacebookPages"].loading = false;

				setModalComponents({
					...modalComponents
				});

				if (data.success)
					actions.push('setPage')

				setTable({
					...table,
					actions: actions
				})

				setAlert({
					...alert,
					show: true,
					type: data.type,
					message: data.message,
				})
			}
			else
				resetToken(data.type, data.message);
		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	};

	const removeEditorPage = (e) =>
	{

		fetch(`${main.apiUrl}/editor/pages`, {
			method: 'DELETE',
			headers: {"Authorization": `Basic ${main.token}`},
			body: JSON.stringify({
				id: modal.id
			}),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			if (!data.hasOwnProperty('invalidToken'))
			{
				let actions = ['undoAll'];

				if (data.success)
					actions.push('setPage')

				setAlert({
					...alert,
					show: true,
					display: true,
					type: data.type,
					message: data.message
				})

				setTable({
					...table,
					actions: actions
				})

				modalComponents[modal.id].show = false;
				modalComponents[modal.id].loading = false;

				setModalComponents({
					...modalComponents
				});
			}
			else
				resetToken(data.type, data.message);
		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	};

	const dismissModal = (e) =>
	{
		modalComponents[modal.id].show = false;
		modalComponents[modal.id].loading = false;

		setModalComponents({
			...modalComponents
		});
	}

	useEffect(() =>
	{
		console.log("This is Editor Page");

		if (main.token && !main.expired)
		{
			table.options = {
				layout: 'fitDataStretch',
				placeholder: "No Editor Pages.",
				ajaxURL: `${main.apiUrl}/editor/pages`,
				columns: [
					{
						formatter: "responsiveCollapse",
						width: 30,
						minWidth: 30,
						hozAlign: "center",
						resizable: false,
						headerSort: false
					},
					{
						title: "Page Name",
						field: "page",
						sorter: "string",
						editor: false,
						validator: 'required',
						widthGrow: 2,
						formatter: (cell, formatterParams, onRendered) =>
						{
							const rowData = cell.getRow().getData();
							return `<img style="border-radius: 50%;width: 2.5rem;margin-right:5px;" src="${rowData.page.picture}"/> ${rowData.page.name}`;
						},
					},
					{
						title: "Page ID",
						field: "pageID",
						sorter: "string",
						editor: false,
						formatter: 'plaintext',
						validator: 'required'
					},
					{
						title: "Status",
						field: "status",
						editor: false,
						sorter: "string",
						formatter: (cell, formatterParams, onRendered) =>
						{
							let rowData = cell.getRow().getData();

							if (rowData.status === 'ACTIVE')
								return `<span class="text-success">ACTIVE</span>`

							if (rowData.status === 'PROCESSING')
								return `<span class="text-warning">PROCESSING......</span>`

							return `<span class="text-danger">INACTIVE</span>`
						}
					},
					{
						title: 'Action',
						field: "status",
						headerSort: false,
						resizable: false,
						formatter: (cell, formatterParams, onRendered) =>
						{
							const rowData = cell.getRow().getData();
							return `<a href="/${rowData.pageID}/inbox">
                                            <button type='button' class='btn btn-outline-primary'>View Inbox Messages</button>
                                        </a><a href="/${rowData.pageID}/posts">
                                            <button type='button' class='btn btn-outline-warning'>View Page Posts</button>
                                        </a>
                                        <button data-action='delete' class='btn btn-outline-danger px-5'>Delete</button>`;
						},
						align: "center",
						cellClick: function (e, cell)
						{
							let rowData = cell.getRow().getData();

							switch (e.target.dataset.action)
							{
								case `delete`:
									setModal({
										...modal,
										id: rowData.id
									})
									break;
							}
						}
					},
				],
			};

			setTable({
				...table
			});

		}
	}, []);

	useEffect(() =>
	{
		if (modalComponents.hasOwnProperty("FacebookPages"))
		{
			if (modalComponents["FacebookPages"].show)
			{
				facebookPageTable.options = {
					layout: "fitData",
					placeholder: "No Facebook Page.",
					responsiveLayout: "collapse",
					pagination: false,
					ajaxSorting: false,
					ajaxFiltering: false,
					ajaxURL: `${main.apiUrl}/facebook/pages`,
					ajaxConfig:
					{
						method: "POST",
						headers:
						{
							"Authorization": `Basic ${main.token}`
						}
					},
					paginationSize: 50,
					paginationButtonCount: 0,
					columns: [
						{
							title: "Page Name",
							field: "page",
							sorter: "string",
							editor: false,
							formatter: (cell, formatterParams, onRendered) =>
							{
								const rowData = cell.getRow().getData();
								return `<img style="border-radius: 50%;width: 2.5rem;margin-right:5px;" src="${rowData.page.picture}"/> ${rowData.page.name}`;
							},
							validator: 'required'
						},
						{
							title: "Page ID",
							field: "id",
							sorter: "string",
							editor: false,
							formatter: 'plaintext',
							validator: 'required'
						},
						{
							title: 'Action',
							field: "id",
							formatter: (cell, formatterParams, onRendered) =>
							{
								let rowData = cell.getRow().getData();

								if (typeof rowData.id !== 'undefined')
									return `<button data-action="add" data-id="${rowData.id}" class='btn btn-theme px-5 addPage'>Add</button>`;
							},
							align: "center",
							cellClick: function (e, cell)
							{
								let rowData = cell.getRow().getData();

								switch (e.target.dataset.action)
								{
									case `add`:
										addEditorPage(rowData.id);
										break;
									default:
										break;
								}
							}
						},
					],
					validationFailed: function (cell, value, validators)
					{
						return cell.cancelEdit();
					},
					ajaxResponse: function (url, params, response)
					{
						console.log(response);

						if (response.hasOwnProperty('invalidToken'))
						{
							resetToken(response.type, response.message);
						}
						else
						{
							facebookPageTable.prev = false;
							facebookPageTable.next = false;

							if (response.paging.hasOwnProperty('previous'))
								facebookPageTable.prev = response.paging.cursors.before;

							if (response.paging.hasOwnProperty('next'))
								facebookPageTable.next = response.paging.cursors.after;

							setFacebookPageTable({
								...facebookPageTable
							});

							return response.data;
						}
					},
				};

				setFacebookPageTable({
					...facebookPageTable
				})
			}
			else
			{
				if (typeof tableComponents[facebookPageTable.id] !== 'undefined')
				{
					tableComponents[facebookPageTable.id].destroy();
					delete tableComponents[facebookPageTable.id];
				}
			}
		}

		if (!modalComponents.hasOwnProperty(modal.id))
		{
			setModal({
				...modal,
				id: false
			})
		}

	}, [modalComponents]);

	useEffect(() =>
	{
		if (modal.id !== false)
		{
			modalComponents[modal.id].show = true;

			setModalComponents({
				...modalComponents,
			})
		}
	}, [modal.id])

	return (
	<>
		<section className={`content ${props.sidebar}`}>
			<header className="content__title">
				<h1 className="mr-auto">Facebook Pages<small>To Perform Facebook Page
					Auto Reply</small></h1>
				<button id="addEditorPage" className="btn btn-theme"
				        onClick={openFacebookPageList}>Add
					Facebook Page
				</button>
			</header>

			<div className="row">
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<Table {...table}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<Modal title={"Add Facebook Page"} scrollable={true} id="FacebookPages" size={"lg"}
		       centered={true}>

			<Table {...facebookPageTable}/>
			<div className="py-3 text-right">
				{(facebookPageTable.prev !== false) &&
				<button type="button" className="btn btn-theme btn--icon-text mr-2"
				        onClick={(e) => fetchFacebookPage('prev')}><i
				className="zwicon-arrow-left"/> Back
				</button>}
				{(facebookPageTable.next !== false) &&
				<button type="button" className="btn btn-theme btn--icon-text"
				        onClick={(e) => fetchFacebookPage('next')}>Next <i
				className="zwicon-arrow-right"/></button>}
			</div>
		</Modal>

		<Modal title={"Remove Facebook Page"} scrollable={false} id={modal.id} size={"sm"}
		       centered={false}>
			If The Facebook Page is Deleted, You and The Related Users Will Not Be Able To View The
			Data.
			<div className="py-3 text-right">
				<button type="button" className="btn btn-danger"
				        onClick={removeEditorPage}>Delete
				</button>
				<button id="cancel" type="button" className="btn btn-link"
				        onClick={dismissModal}>Cancel
				</button>

			</div>
		</Modal>
	</>
	);
}