import React, {useContext, useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {DateRangePicker} from 'rsuite';
import {ResponsiveBar} from '@nivo/bar';
import {ResponsiveLine} from '@nivo/line';
import moment from 'moment-timezone';

import {MainContext} from "../../contexts/MainContext";
import {AnalyticContext} from "../../contexts/AnalyticContext";

import Loading from "../../components/Loading";

export default function AnalyticFansOnlineContainer(props) {
    const {main} = useContext(MainContext);
    const {analytic} = useContext(AnalyticContext);

    const {id} = useParams();

    const theme = {
        background: "transparent",
        fontSize: 16,
        axis: {
            tickColor: "#9bbcd1",
            ticks: {
                line: {
                    stroke: "#9bbcd1"
                },
                text: {
                    fill: "#9bbcd1"
                }
            },
            legend: {
                text: {
                    fill: "#9bbcd1"
                }
            }
        },
        grid: {
            line: {
                stroke: "#9bbcd1"
            }
        }
    };

    const [fans, setFans] = useState({
        weekly: false,
        hourly: false,
        loading: false,
        hourMax: 0,
        hourMin: 0
    })

    const Labels = ({bars, yScale}) => {
        // space between top of stacked bars and total label
        const labelMargin = 70;

        return bars.map(({data: {data, indexValue}, x, width}, i) => {
            return (
                <g
                    className={`labels`}
                    transform={`translate(${x}, ${yScale(parseFloat(data['value']))})`}
                    key={`${indexValue}-${i}`}
                >
                    <text
                        // add any class to the label here
                        className="bar-total-label"
                        x={width / 2}
                        y={parseFloat(data['value'])}
                        textAnchor="middle"
                        alignmentBaseline="central"
                        fontSize={`18px`}
                        fontWeight={`700`}
                        style={{
                            fill: '#ffffff'
                        }}
                    >
                        {data['value']}
                    </text>
                </g>
            );
        });
    };

    useEffect(() => {
        setFans({
            ...fans,
            loading: true,
        })

        fetch(`${main.apiUrl}/analytic/fans`, {
            method: 'POST',
            headers: {"Authorization": `Basic ${main.token}`},
            body: JSON.stringify({
                pageID: id,
                since: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                until: moment().format('YYYY-MM-DD'),
            }),
        }).then(response => response.json())    // one extra step
            .then(data => {
                if (!data.hasOwnProperty('invalidToken')) {
                    console.log(data.data);

                    setFans({
                        ...fans,
                        weekly: data.data.weekly,
                        hourly: data.data.hourly,
                        hourMin: data.data.hourMin,
                        hourMax: data.data.hourMax
                    })
                } else
                    main.resetToken(data.type, data.message);

            }).catch((err) => {
            console.log('錯誤:', err);
        })
    }, [])

    const getSummaryMetrics = (since = null, until = null) => {
        /*until = moment(until).add(2, 'days').format('YYYY-MM-DD');*/

        setFans({
            ...fans,
            loading: true,
        })

        fetch(`${main.apiUrl}/analytic/fans`, {
            method: 'POST',
            headers: {"Authorization": `Basic ${main.token}`},
            body: JSON.stringify({
                pageID: id,
                since: since,
                until: until,
            }),
        }).then(response => response.json())    // one extra step
            .then(data => {
                if (!data.hasOwnProperty('invalidToken')) {
                    console.log(data.data);

                    setFans({
                        ...fans,
                        weekly: data.data.weekly,
                        hourly: data.data.hourly,
                        hourMin: data.data.hourMin,
                        hourMax: data.data.hourMax
                    })
                } else
                    main.resetToken(data.type, data.message);

            }).catch((err) => {
            console.log('錯誤:', err);
        })
    }

    return (
        <section className={`content analytic ${props.sidebar}`}>
            <header className="content__title">
                <h1
                    className="mr-auto">Fans Online Distribution<small>Distribution Of {analytic.pageName} Active Fans
                    By Day And Time</small>
                </h1>
                <button id="exportData" className="btn btn-theme">Export
                </button>
            </header>

            <div className="dateRangePickerWrapper">

                <Loading componentLoader={true} loading={fans.loading}/>

                <DateRangePicker cleanable={false}
                                 ranges={[]}
                                 defaultValue={[moment().subtract(7, 'days').toDate(),
                                     moment().toDate()
                                 ]}
                                 disabledDate={date => moment(date).isAfter(moment())}
                                 onChange={(dates) => {
                                     getSummaryMetrics(dates[0], dates[1])
                                 }}
                />
            </div>

            <div className="row mx-0">
                <h4 className="mr-auto my-4">Day of Week Distribution</h4>
            </div>

            <div className="row" style={{height: '500px'}}>
                {(fans.weekly) &&
                <ResponsiveBar
                    data={fans.weekly}
                    keys={["value"]}
                    indexBy="key"
                    colorBy={`index`}
                    colors={["#27a4fb", "#2cc56f", "#ffc021", "#f93964", "#00f3e7", "#9bbcd1"/*, "#dcf3ff"*/, "#ffe21f"]}
                    padding={0.3}
                    theme={theme}
                    /*enableLabel={false}*/
                    labelTextColor={'#ffffff'}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: 'middle',
                        legendOffset: 32
                    }}
                    enableGridY={false}
                    axisLeft={null}
                    margin={{top: 50, right: 80, bottom: 50, left: 80}}
                    tooltip={(data) => {
                        console.log(data);
                        return <div className="d-flex flex-row align-items-end justify-content-around"
                                    style={{background: '#fff', padding: '1rem 1.2rem', borderRadius: '1rem'}}>
                            <div className='align-self-center mr-2'
                                 style={{
                                     width: '1rem',
                                     background: data.color,
                                     borderRadius: '.1rem',
                                     padding: '.5rem 0'
                                 }}/>
                            <div className='align-self-center'
                                 style={{color: '#22313a', fontWeight: '500'}}>
                                {data.indexValue}: {data.value}
                            </div>
                        </div>
                    }}
                    /*layers={['grid', 'axes', 'bars',Labels, 'markers', 'legends', 'annotations']}*/
                />}
            </div>

            <div className="row mx-0">
                <h4 className="mr-auto my-4">Hourly Distribution</h4>
            </div>

            <div className="row" style={{height: '500px'}}>
                {(fans.hourly) &&
                <ResponsiveLine
                    data={[
                        {
                            id: 'Fans Online',
                            data: fans.hourly
                        }
                    ]}
                    sliceTooltip={({slice}) => {
                        return <div className="sliceToolTips">
                            <div>
                                <table>
                                    <tbody>
                                    {slice.points.slice(0).reverse().map((point, index) => {
                                        let html = '';

                                        if (index === 0) {
                                            return <>
                                                <tr>
                                                    <td>
                                                        {point.data.xFormatted}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <strong>{(point.data.yFormatted >= 0) ? point.data.yFormatted : -1 * point.data.yFormatted}</strong><span
                                                        style={{'color': point.borderColor}}><strong>{point.serieId}</strong></span>
                                                    </td>
                                                </tr>
                                            </>
                                        }

                                        return <tr>
                                            <td>
                                                <strong>
                                                    {(point.data.yFormatted > 0) ? point.data.yFormatted : -1 * point.data.yFormatted}
                                                </strong><span
                                                style={{'color': point.borderColor}}><strong>{point.serieId}</strong></span>
                                            </td>
                                        </tr>

                                        /*return <tr>
                                            <td><span style={{'backgroundColor': point.borderColor}}></span>
                                            </td>
                                            <td>{point.serieId}</td>
                                            <td><strong>8</strong></td>
                                        </tr>*/

                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }}
                    curve={"linear"}
                    margin={{top: 50, right: 70, bottom: 50, left: 70}}
                    enableSlices={'x'}
                    enableArea={true}
                    areaOpacity={0.1}
                    enablePoints={true}
                    xScale={{type: 'point'}}
                    yScale={{type: 'linear', min: fans.hourMin, max: fans.hourMax, stacked: false, reverse: false}}
                    axisTop={null}
                    axisRight={null}
                    /*axisBottom={null}*/
                    /*axisLeft={null}*/
                    enableGridX={true}
                    enableGridY={true}
                    colors={['#27a4fb', '#f93964', '#ffe21f'/*, '#f93964'*//*,'#2cc56f'*//*,'#ffc021'*/]}
                    pointSize={10}
                    pointColor={{theme: 'background'}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: 'serieColor'}}
                    pointLabel="y"
                    pointLabelYOffset={-12}
                    useMesh={true}
                    theme={theme}
                    animate={true}
                    crosshairType={`bottom-left`}
                />
                }
            </div>
        </section>
    );
}