import React, {useContext, useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import {DateRangePicker} from 'rsuite';
import moment from 'moment-timezone';

import {MainContext} from "../../../contexts/MainContext";
import {AnalyticContext} from "../../../contexts/AnalyticContext";

import Loading from "../../../components/Loading";
import Metric from "../../../components/Metric";

export default function MetricMonthlyContainer(props)
{
	const {main} = useContext(MainContext);
	const {analytic} = useContext(AnalyticContext);

	const {id} = useParams();

	const [metrics, setMetrics] = useState({
		metrics: false,
		loading: false,
	})

	useEffect(() =>
	{
		setMetrics({
			...metrics,
			loading: true,
		})

		fetch(`${main.apiUrl}/analytic/metric/monthly`, {
			method: 'POST',
			headers: {"Authorization": `Basic ${main.token}`},
			body: JSON.stringify({
				pageID: id,
				since: moment().subtract(7, 'days').format('YYYY-MM-DD'),
				until: moment().format('YYYY-MM-DD'),
			}),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			if (!data.hasOwnProperty('invalidToken'))
			{
				console.log(data.metrics);

				setMetrics({
					...metrics,
					metrics: data.metrics,
				})
			}
			else
				main.resetToken(data.type, data.message);

		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	}, [])

	const getSummaryMetrics = (since = null, until = null) =>
	{
		/*until = moment(until).add(2, 'days').format('YYYY-MM-DD');*/

		setMetrics({
			...metrics,
			loading: true,
		})

		fetch(`${main.apiUrl}/analytic/metric/monthly`, {
			method: 'POST',
			headers: {"Authorization": `Basic ${main.token}`},
			body: JSON.stringify({
				pageID: id,
				since: since,
				until: until,
			}),
		}).then(response => response.json())    // one extra step
		.then(data =>
		{
			if (!data.hasOwnProperty('invalidToken'))
			{
				console.log(data.metrics);

				setMetrics({
					...metrics,
					metrics: data.metrics,
					loading: false,
				})
			}
			else
				main.resetToken(data.type, data.message);

		}).catch((err) =>
		{
			console.log('錯誤:', err);
		})
	}

	return (
	<section className={`content analytic ${props.sidebar}`}>
		<header className="content__title">
			<h1 className="mr-auto">Metrics<small>Summary Metrics of {analytic.pageName}</small>
			</h1>
			<button id="exportData" className="btn btn-theme">Export
			</button>
		</header>

		<div className="dateRangePickerWrapper">

			<Loading componentLoader={true} loading={metrics.loading}/>

			<DateRangePicker cleanable={false}
			                 format={`YYYY-MM`}
			                 hoverRange="month"
			                 ranges={[]}
			                 defaultValue={[moment().subtract(7, 'days').toDate(),
				                 moment().toDate()
			                 ]}
			                 disabledDate={date => moment(date).isAfter(moment())}
			                 onChange={(dates) =>
			                 {
				                 getSummaryMetrics(dates[0], dates[1])
			                 }}
			/>
		</div>

		<div className="row">
			{
				(metrics.metrics) && metrics.metrics.map((metric, index) =>
				{
					console.log(metric);
					return <Metric {...metric}/>
				})
			}
		</div>
	</section>
	);
}