import React, {useContext, useState, useEffect} from 'react';
import {Redirect, useParams} from "react-router-dom";

import {AnalyticContext} from "../contexts/AnalyticContext";

import Sidebar from "./Sidebar";
import Notifications from "./Notifications";
import Notification from "./Notification";
import Header from "./Header";
import MenuItem from "./MenuItem";


export default function AnalyticPageLayout({component: Component, sidebar: sidebar})
{
    const {analytic} = useContext(AnalyticContext);
    const {id} = useParams();

    const toggleSubMenu = (e) =>
    {
        e.preventDefault();
        e.stopPropagation();
        e.target.parentNode.classList.toggle("active");
    }

    return (
    <>
        <Header sidebar={sidebar} pageID={id} title={analytic.pageName}/>

        <div className="main">
            {(sidebar) &&
            <>
                <Notifications>
                    <Notification/>
                </Notifications>

                <Sidebar>
                    <li>
                        <MenuItem activeColor={"white"} icon={"arrow-left"} label={"Back To Home"}
                                  location={`/`}/>
                    </li>

                    <li className="navigation__sub">
                        <a href="" data-mae-action="submenu-toggle"
                           className="text-active-blue" onClick={toggleSubMenu}><i
                        className="zwicon-paper-prototype text-blue"/>Page Analytics</a>
                        <ul style={{listStyleType: 'none'}}>

                            <li className="navigation__sub">
                                <a href="" data-mae-action="submenu-toggle"
                                   className="text-active-green" onClick={toggleSubMenu}><i
                                className="zwicon-activity text-warning"/>Metrics</a>
                                <ul style={{listStyleType: 'none'}}>
                                    <li>
                                        <MenuItem activeColor={"danger"} icon={"web"}
                                                  label={"Summary"}
                                                  location={`/${id}/metrics/summary`}/>
                                    </li>
                                    <li>
                                        <MenuItem activeColor={"danger"} icon={"calendar-month"}
                                                  label={"Monthly"}
                                                  location={`/${id}/metrics/monthly`}/>
                                    </li>
                                </ul>
                            </li>

                            <li className="navigation__sub">
                                <a href="" data-mae-action="submenu-toggle"
                                   className="text-active-green" onClick={toggleSubMenu}><i
                                className="zwicon-paper-prototype text-warning"/>Demographics</a>
                                <ul style={{listStyleType: 'none'}}>
                                    <li>
                                        <MenuItem activeColor={"danger"} icon={"thumbs-up"}
                                                  label={"Fans Demographics"}
                                                  location={`/${id}/demographics/like`}/>
                                    </li>

                                    <li>
                                        <MenuItem activeColor={"danger"} icon={"scale"}
                                                  label={"Reach Demographics"}
                                                  location={`/${id}/demographics/reach`}/>
                                    </li>
                                </ul>
                            </li>

                            <li>
                                <MenuItem activeColor={"warning"} icon={"users"} label={"Active Fans"}
                                          location={`/${id}/fans`}/>
                            </li>
                        </ul>
                    </li>
                </Sidebar>
            </>
            }

            <Component sidebar={sidebar}/>

        </div>
    </>
    )
}