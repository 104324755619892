import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import '../assets/scss/App.scss';

import MainContextProvider from "../contexts/MainContext";
import ComponentContextProvider from "../contexts/ComponentContext";
import AnalyticContextProvider from "../contexts/AnalyticContext";

import LoginContainer from './LoginContainer';
import ActivationContainer from "./ActivationContainer";
import EditorPageContainer from "./EditorPageContainer";
import MetricSummaryContainer from "./Analytic/Metric/SummaryContainer";
import LikeDemographicContainer from "./Analytic/Demographic/LikeContainer";
import ReachDemographicContainer from "./Analytic/Demographic/ReachContainer";
import EngagementDemographicContainer from "./Analytic/Demographic/EngagementContainer";
import AnalyticFansOnlineContainer from "./Analytic/FansOnlineContainer";
import MetricMonthlyContainer from "./Analytic/Metric/MonthlyContainer";
import ExportContainer from "./ExportContainer";
import FacebookPostContainer from "./FacebookPostContainer";
import FacebookPostCommentContainer from "./FacebookPostCommentContainer";
import FacebookInboxContainer from "./FacebookInboxContainer";

import Loading from "../components/Loading";
import Alert from "../components/Alert";
import EditorPageLayout from "../components/EditorPageLayout";
import AnalyticPageLayout from "../components/AnalyticPageLayout";
import UnAuthorized from "../components/UnAuthorized";
import Editor from "../components/Editor";
import NonActivated from "../components/NonActivated";
import Analytic from "../components/Analytic";






const App = () =>
{
	return (
	<ComponentContextProvider>
		<>
			<Router basename="/">
				<MainContextProvider>
					<Loading/>
					<Alert/>
					<Switch>
						<Route exact path="/export"
							   render={(props) =><ExportContainer/>}/>

						<Route exact path="/login"
						       render={(props) =>
						       <UnAuthorized><LoginContainer/></UnAuthorized>}/>

						<Route exact path="/activate"
						       render={(props) =>
						       <NonActivated><ActivationContainer/></NonActivated>}/>
						<Route exact path="/"
						       render={(props) => <Editor><EditorPageLayout
						       component={EditorPageContainer}
						       sidebar={"with-sidebar"}/></Editor>}/>

						<Route exact path="/:id/posts"
						       render={(props) => <Editor><EditorPageLayout
						       component={FacebookPostContainer}
						       sidebar={"with-sidebar"}/></Editor>}/>

						<Route exact path="/:id/inbox"
							   render={(props) => <Editor><EditorPageLayout
							   component={FacebookInboxContainer}
							   sidebar={"with-sidebar"}/></Editor>}/>

						<Route exact path="/post/:id"
							   render={(props) => <Editor><EditorPageLayout
							   component={FacebookPostCommentContainer}
							   sidebar={"with-sidebar"}/></Editor>}/>

						<Route exact path="/:id/metrics/summary"
						       render={(props) =>
						       <Editor>
							       <AnalyticContextProvider>
								       <Analytic>
									       <AnalyticPageLayout
									       component={MetricSummaryContainer}
									       sidebar={"with-sidebar"}/>
								       </Analytic>
							       </AnalyticContextProvider>
						       </Editor>}/>

						<Route exact path="/:id/metrics/monthly"
						       render={(props) =>
						       <Editor>
							       <AnalyticContextProvider>
								       <Analytic>
									       <AnalyticPageLayout
									       component={MetricMonthlyContainer}
									       sidebar={"with-sidebar"}/>
								       </Analytic>
							       </AnalyticContextProvider>
						       </Editor>}/>

						<Route exact path="/:id/demographics/like"
						       render={(props) =>
						       <Editor>
							       <AnalyticContextProvider>
								       <Analytic>
									       <AnalyticPageLayout
									       component={LikeDemographicContainer}
									       sidebar={"with-sidebar"}/>
								       </Analytic>
							       </AnalyticContextProvider>
						       </Editor>}/>

						<Route exact path="/:id/demographics/reach"
						       render={(props) =>
						       <Editor>
							       <AnalyticContextProvider>
								       <Analytic>
									       <AnalyticPageLayout
									       component={ReachDemographicContainer}
									       sidebar={"with-sidebar"}/>
								       </Analytic>
							       </AnalyticContextProvider>
						       </Editor>}/>

						<Route exact path="/:id/demographics/engagement"
						       render={(props) =>
						       <Editor>
							       <AnalyticContextProvider>
								       <Analytic>
									       <AnalyticPageLayout
									       component={EngagementDemographicContainer}
									       sidebar={"with-sidebar"}/>
								       </Analytic>
							       </AnalyticContextProvider>
						       </Editor>}/>

						<Route exact path="/:id/fans"
						       render={(props) =>
						       <Editor>
							       <AnalyticContextProvider>
								       <Analytic>
									       <AnalyticPageLayout
									       component={AnalyticFansOnlineContainer}
									       sidebar={"with-sidebar"}/>
								       </Analytic>
							       </AnalyticContextProvider>
						       </Editor>}/>

						<Route render={props => <Redirect to={`/`}/>}/>
					</Switch>
				</MainContextProvider>
			</Router>
		</>
	</ComponentContextProvider>
	);
}

export default App;