import React from 'react';
import {ResponsiveBar,ResponsiveBarCanvas} from "@nivo/bar";

export default function ExportContainer(props)
{
    const data = [
        {quarter: 1, earnings: 13000},
        {quarter: 2, earnings: 16500},
        {quarter: 3, earnings: 14250},
        {quarter: 4, earnings: 19000}
    ];

    return (
    <>
        <div>
            <div className="d-flex align-items-center justify-content-center" style={{
                width: '1920px',
                height: '1188px',
            }}>
                <div className="row mx-0" style={{height: '500px', width: '100%',backgroundColor:'#000'}}>
                    <ResponsiveBarCanvas data={data} keys={["earnings"]} indexBy="quarter"
                                   margin={{top: 50, right: 0, bottom: 50, left: 0}}/>
                </div>
            </div>
        </div>
        <div>
            <div className="d-flex align-items-center justify-content-center" style={{
                width: '1920px',
                height: '1188px',
            }}>
                <div className="row mx-0" style={{height: '500px', width: '90%',backgroundColor:'#000',borderLeft:'10% solid #f00'}}>
                    {/*<ResponsiveBar data={data} keys={["earnings"]} indexBy="quarter"
                                   margin={{top: 50, right: 60, bottom: 50, left: 60}}/>*/}
                </div>
            </div>
        </div>
        <div>
            <div className="d-flex align-items-center justify-content-center" style={{
                width: '1920px',
                height: '1188px',
            }}>
                <div className="row mx-0" style={{height: '500px', width: '90%',backgroundColor:'#000',borderLeft:'10% solid #f00'}}>
                    {/*<ResponsiveBar data={data} keys={["earnings"]} indexBy="quarter"
                                   margin={{top: 50, right: 60, bottom: 50, left: 60}}/>*/}
                </div>
            </div>
        </div>
    </>
    )
    ;
}