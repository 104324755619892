import React from 'react';

export default function DemographicRanking(props)
{
	return (
	<div className='col-lg-4 col-12'>
		<h5 className="mr-auto mb-4">{props.title}</h5>
		<table className="table table-striped mb-0">
			<thead>
			<tr>
				<th>#</th>
				<th className={`text-capitalize`}>{props.type}</th>
				<th>Fans</th>
			</tr>
			</thead>
			<tbody>
			{(props.data) && props.data.map((item,index) =>
			{
				return <tr>
					<th scope="row">{index+1}</th>
					<td>{item[props.type]}</td>
					<td>{item.value}</td>
				</tr>
			})}

			</tbody>
		</table>
	</div>
	);
}