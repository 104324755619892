import React, {useContext} from 'react';
import io from "socket.io-client";
import {Link} from "react-router-dom";

import {MainContext} from "../contexts/MainContext";
import {ComponentContext} from "../contexts/ComponentContext";

export default function Header(props)
{
    const {main, setMainContext} = useContext(MainContext);
    const {notification, setNotification} = useContext(ComponentContext);

    const logout = () =>
    {
        if (main.token !== null)
        {
            window.localStorage.removeItem('token');

            setMainContext({
                ...main,
                init: false,
                socket: null,
                token: null,
                expired: true,
                activated: false,
            })
        }
    };

    const toggleNotification = (e) =>
    {
        console.log("Toggling Notification");

        setNotification({
            ...notification,
            show: true,
            id: e.target.dataset.notification,
        })
    }

    const toggleMenu = (e) =>
    {
        document.getElementById('mainMenu').classList.toggle("sidebar--active");
        document.getElementById('mainmenu-backdrop').classList.toggle("active");
    }

    return (
    <header className="header">
        <div className="header__main">
            <i className="navigation-toggle zwicon-hamburger-menu d-xl-none" onClick={toggleMenu}></i>

            <div className={`logo d-none d-md-flex mt-xl-3 with-sidebar align-items-center  ${props.sidebar}`}>
                <img className="mr-2 mb-1" src={process.env.PUBLIC_URL + "/images/logo.png"}/>
                {
                    (props.pageID && props.title) ? <Link to={`/${props.pageID}`}>{props.title}</Link> : <Link to={`/`}>Page Auto Reply</Link>
                }
            </div>

            <ul className="top-nav">
                <li className={`dropdown d-block ${props.sidebar}`}>
                    <a data-toggle="dropdown" onClick={logout}><i
                    className="zwicon-sign-out"></i></a>
                </li>
            </ul>
        </div>
        {/*{(props.sidebar !== null) &&
		<div className="toggles d-none d-xl-block">
			<a data-notification="#notifications-messages" className="toggles__notify"
			   onClick={toggleNotification}><i
			className="zwicon-mail"/></a>
			<a href="" data-notification="#notifications-alerts"><i className="zwicon-bell"/></a>
			<a href="" data-notification="#notifications-tasks"><i className="zwicon-task"/></a>
		</div>
		}*/}
    </header>
    );
};